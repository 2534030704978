import React from "react";
import { SEO, PageLayout, HeroElement, ContactForm } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={[
                "https://fisherman.gumlet.io/public/tacoselprimoauthenticmexicangrill/tacos_el_primohenderson_blvd_hero.JPG",
              ]}
              ctas={[
                fishermanBusiness.ctas.address,
                fishermanBusiness.ctas.phone,
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container catering-page"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column computer={12} mobile={16}>
              <ContactForm
                destinationEmail={"tacoselprimotampa@gmail.com"}
                buttonText={"Submit"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Submit A Catering Request"}
                withContainer={true}
                termsCopy={""}
                subheader={""}
                showLabels={true}
                emailSubject={"Catering Request"}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        address {
          text
          link
          internal
        }
        phone {
          text
          link
          internal
        }
      }
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Catering" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
